
import { defineComponent, onActivated, shallowRef } from 'vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import Scroller from 'common/providers/Scroller.vue'
import TheTradingItem from '@/modules/copyTrade/components/TheTradingItem.vue'
import { getCopyerTraderList, ITraderItem } from '@/modules/copyTrade/copyTrade.api'
import TheMaximum from '@/modules/copyTrade/components/TheMaximum.vue'

export default defineComponent({
  name: 'MyCopying',
  components: { TheMaximum, Holder, Scroller, TheTradingItem, AsyncNullableList },
  setup () {
    const traderList = shallowRef<ITraderItem[]>([])
    const freshStatus = shallowRef(0)

    // 获取列表接口
    const getList = async () => {
      return getCopyerTraderList().then((res) => {
        traderList.value = res.models ? res.models : []
      })
    }

    // item组件跟单操作时。通知该页面刷新占比设置
    const handelRefresh = () => {
      freshStatus.value = Date.parse(String(new Date()))
    }

    onActivated(getList)

    return {
      traderList,
      freshStatus,
      handelRefresh,
      getList,
      load (done: () => void) {
        return getList().then(done)
      },
    }
  },
})
