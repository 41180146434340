
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'
import router from '@/router'

export default defineComponent({
  name: 'TheRecommend',
  components: { Pic },
  props: {
    firstModel: {
      type: Object,
      required: true,
    },
  },
  setup () {
    //
    const pageToInfo = (id: string) => {
      router.push('/trade/copy/' + id)
    }
    return {
      pageToInfo,
    }
  },
})
