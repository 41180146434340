
import { openDialog } from '@/components/popup/popup'
import WTTabs from '@/components/Tabs.vue'
import { keymap } from '@/config'
import TheCopyGuideDialog from '@/modules/copyTrade/components/TheCopyGuideDialog.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { localGet, localSet } from 'essential/store/localStore'
import { defineComponent } from 'vue'
import Traders from '@/modules/copyTrade/Traders.vue'
import MyCopying from '@/modules/copyTrade/MyCopying.vue'

export default defineComponent({
  name: 'CopyTrade',
  components: {
    MyCopying,
    Traders,
    WTTabs,
    PageWithHeader,
  },
  setup () {
    const showGuide = () => {
      openDialog(TheCopyGuideDialog, { wrapperClass: 'center' }, { transition: 'slide-down' })
    }

    if (!localGet(keymap.guide.copyGuideDialog)) {
      showGuide()
      localSet(keymap.guide.copyGuideDialog, '1')
    }

    return {
      showGuide,
    }
  },
})
