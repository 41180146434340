
import { defineComponent, reactive, watch } from 'vue'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import TheLabel from '@/modules/copyTrade/components/TheLabel.vue'
import { useCopyTrade } from '@/modules/copyTrade/copyTrade'
import { mul } from 'essential/tools/math'
import { ITraderItem } from '@/modules/copyTrade/copyTrade.api'
import Button from '@/components/Button.vue'

export default defineComponent({
  name: 'TheTradingItem',
  components: { Line, Pic, TheLabel, Button },
  emits: ['refresh'],
  props: {
    modelItem: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const model = reactive(props.modelItem)
    model.totalRatio = mul(props.modelItem.totalRatio, 100)
    const { loading, toggle } = useCopyTrade()
    watch(() => props.modelItem, () => {
      model.nikeName = props.modelItem.nikeName
      model.synopsis = props.modelItem.synopsis
      model.labels = props.modelItem.labels
      model.likes = props.modelItem.likes
      model.copyers = props.modelItem.copyers
      model.copyStatus = props.modelItem.copyStatus
      model.totalRatio = mul(props.modelItem.totalRatio, 100)
    })
    const handleCopy = () => {
      if (model === undefined) {
        return false
      }
      toggle(model as ITraderItem, () => {
        model.copyStatus = !model.copyStatus
        model.copyers = +model.copyers + (model.copyStatus ? 1 : -1)
        ctx.emit('refresh')
      })
    }
    return {
      model,
      loading,
      handleCopy,
    }
  },
})
