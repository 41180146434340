
import { defineComponent, onActivated, ref, shallowRef } from 'vue'
import TheRecommend from '@/modules/copyTrade/components/TheRecommend.vue'
import Scroller from 'common/providers/Scroller.vue'
import Holder from '@/provider/Holder.vue'
import { translate } from '@/i18n'
import TheTradingItem from '@/modules/copyTrade/components/TheTradingItem.vue'
import { getTraderList, ITraderItem, ILabels } from '@/modules/copyTrade/copyTrade.api'
import List from '@/components/AsyncNullableList.vue'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'Traders',
  components: { TheRecommend, Scroller, TheTradingItem, Holder, List },
  setup () {
    const label = shallowRef<ILabels>({
      ALL: translate('copytrading_23'),
    }) // 获取所有标签信息
    const curLabelKey = shallowRef()
    const activeLabel = ref(0) // 记录当前选中的标签
    const firstModel = ref<ITraderItem>() // 推荐交易员信息
    const modelItems = ref<ITraderItem[]>() // 交易员列表信息（除推荐交易员）

    // 根据标签切换搜索列表
    const change = (type: number, key: string) => {
      activeLabel.value = type
      if (type === 0) {
        curLabelKey.value = ''
      } else {
        curLabelKey.value = key
      }
      refresh()
    }

    const refresh = async () => {
      return getTraderList({ label: curLabelKey.value }).then((res) => {
        res.firstModel.totalRatio = mul(res.firstModel.totalRatio, 100)
        firstModel.value = res.firstModel
        modelItems.value = res.models
        for (const item in res.labels) {
          label.value[item] = res.labels[item]
        }
      })
    }

    onActivated(() => {
      refresh()
    })

    return {
      label,
      firstModel,
      modelItems,
      activeLabel,
      change,
      load (done: () => void) {
        return refresh().then(done)
      },
    }
  },
})
